<template>
    <form-modal
        ref="formModal"
        :title="i18nTitleModal"
        :in-editing="isEdit"
        size="xl"
        :handle-close="handleCancel"
    >
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nWorkSchedule"
                    :operation="isEdit ? modalOperation : 'deleted'"
                />
                <feedback-modal
                    ref="feedbackModal"
                    :title="i18nWorkSchedule"
                />
                <b-row v-if="!isEdit" class="mb-2">
                    <div class="col-sm-6 form-inline">
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <edit-label-button
                                v-if="$can('UPDATE', 'WORKSCHEDULE')"
                                :title="getI18n(i18nKey, 'TITLES.work_schedule')"
                                :disabled="deleteLoading"
                                @onClick="isEdit = true"
                            />
                           <delete-label-button
                                v-if="$can('DELETE', 'WORKSCHEDULE')"
                                :title="getI18n(i18nKey, 'TITLES.work_schedule')"
                                :busy="deleteLoading"
                                @onClick="handleDelete"
                            />
                        </div>
                    </div>
                </b-row>
                <b-row>
                    <b-col md="4">
                        <b-form-group
                            :label="getI18n('POS', 'TITLES.pos')"
                            class="required label-pdv"
                            label-for="modal-type"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_pos')"
                        >
                            <multiSelectWithService
                                :id="'pos'"
                                ref="posMultiSelect"
                                v-model="modalWorkSchedule.pos"
                                :v="$v.modalWorkSchedule.pos_id"
                                :class="validationClass($v.modalWorkSchedule.pos_id)"
                                :service="'pos'"
                                :searchable="true"
                                :multiple="false"
                                :disabled="!isEdit || isWorkSchedulesCreated || submitLoading"
                                @input="changePosId"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group
                            :label="getI18n('USERS', 'TITLES.user')"
                            class="required label-pdv"
                            label-for="modal-type"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_user')"
                        >
                            <multiSelectWithService
                                :id="'user'"
                                ref="userMultiSelect"
                                v-model="modalWorkSchedule.user"
                                :v="$v.modalWorkSchedule.user_id"
                                :class="validationClass($v.modalWorkSchedule.user_id)"
                                :service="'users'"
                                :searchable="true"
                                :multiple="false"
                                disabled
                                @input="changeUserId"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group
                            :label="getI18n('MIXES', 'TITLES.mix')"
                            class="required label-pdv"
                            label-for="modal-type"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_mix')"
                        >
                            <multiSelectWithService
                                :id="'mix'"
                                ref="mixMultiSelect"
                                v-model="modalWorkSchedule.mix"
                                :v="$v.modalWorkSchedule.mix_id"
                                :class="validationClass($v.modalWorkSchedule.mix_id)"
                                :service="'mixes'"
                                :searchable="true"
                                :multiple="false"
                                :disabled="!isEdit || isWorkSchedulesCreated || submitLoading"
                                :label="'name_with_business_unit'"
                                search-label="name"
                                :parameters="multiselectMixParameters"
                                @input="changeMixId"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="4">
                        <b-form-group
                            :label="i18nFrequency"
                            class="required label-pdv"
                            label-for="itemText"
                            :invalid-feedback="i18nInvalidFrequency"
                        >
                            <treeselect
                                id="filter-frequency"
                                v-model="$v.modalWorkSchedule.human_readable_frequencies.$model"
                                :class="validationClass($v.modalWorkSchedule.human_readable_frequencies)"
                                :multiple="true"
                                :options="workScheduleFrequencies"
                                :value-consists-of="valueConsistsOf"
                                :placeholder="i18nFrequency"
                                :disabled="!isEdit || submitLoading"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group
                            :label="getI18n('COMMON', 'start_date')"
                            class="required label-pdv"
                            label-for="modal-start-date"
                            :invalid-feedback="i18nInvalidDate"
                        >
                            <date-picker
                                id="modal-start-date"
                                :child-class="validationClass($v.modalWorkSchedule.start_date)"
                                :value.sync="$v.modalWorkSchedule.start_date.$model"
                                :min-date="modalWorkSchedule.last_start_date ? modalWorkSchedule.last_start_date : new Date()"
                                :disabled="!isEdit || submitLoading"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group
                            :label="getI18n('COMMON', 'end_date')"
                            class="label-pdv"
                            label-for="modal-end-date"
                            :invalid-feedback="i18nInvalidDate"
                        >
                            <date-picker
                                id="modal-end-date"
                                :child-class="validationClass($v.modalWorkSchedule.end_date)"
                                :value.sync="$v.modalWorkSchedule.end_date.$model"
                                :disabled="!isEdit || submitLoading"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="3">
                        <b-form-group
                            class="required label-pdv"
                            :label="i18nPriority"
                            label-for="modal-is-priority"
                        >
                            <translated-multiselect
                                id="modal-is-priority"
                                v-model="priority"
                                :options="priorityTypes"
                                :select-class="validationClass($v.modalWorkSchedule.priority_order)"
                                track-by="value"
                                label="text"
                                :disabled="!isEdit || submitLoading"
                                @input="changePriority"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>
        </template>
        <template v-slot:footer>
            <div v-if="isEdit" class="d-flex justify-content-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <save-button
                    v-if="isWorkSchedulesCreated"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
                <add-button
                    v-else
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import { Treeselect } from '@riophae/vue-treeselect'
import { numeric, required, minLength } from 'vuelidate/lib/validators'
import WorkScheduleService from '@src/services/WorkScheduleService'
import formModal from '@/src/components/FormModal.vue'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import translatedMultiselect from '@src/components/translatedMultiselect'
import multiSelectWithService from '@src/components/multiSelectWithService'
import workScheduleFrequencies from '@src/mixins/workScheduleFrequencies'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validation from '@src/mixins/validation.vue'
import CancelButton from '@/src/components/CancelButton'
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import EditLabelButton from '@/src/components/EditLabelButton.vue'
import DeleteLabelButton from '@/src/components/DeleteLabelButton.vue'
import DatePicker from '@/src/components/DatePicker'
import priorityTypes from '@src/mixins/priorityTypes.vue'
import moment from 'moment';

const i18nKey = 'WORKSCHEDULES'
const i18nCommon = 'COMMON'

export default {
    components: {
        Treeselect,
        formModal,
        ConfirmationFormModal,
        FeedbackModal,
        translatedMultiselect,
        multiSelectWithService,
        CancelButton,
        AddButton,
        SaveButton,
        EditLabelButton,
        DeleteLabelButton,
        DatePicker,
    },
    mixins: [
        validation,
        swalFeedback,
        workScheduleFrequencies,
        priorityTypes,
    ],
    props: {
        modal: {
            type: Object,
            default: () => {
                return {
                    id: null,
                    pos_id: null,
                    pos: {
                        id: 0,
                        name: '',
                    },
                    user_id: null,
                    user: {
                        id: 0,
                        name: '',
                    },
                    mix_id: null,
                    mix: {
                        id: 0,
                        name: '',
                        products: [],
                    },
                    start_date: null,
                    end_date: null,
                    human_readable_frequencies: [],
                }
            },
        },
        modalIsEdit: {
            required: true,
            type: Boolean,
        },
    },
    data() {
        return {
            i18nKey,
            i18nCommon,
            multiselectMixParameters: {
                with: ['businessUnit'],
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            modalWorkSchedule: this.modal,
            isEdit: false,
            submitLoading: false,
            deleteLoading: false,
            priority: null,
            valueConsistsOf: 'BRANCH_PRIORITY',
        }
    },
    validations: {
        modalWorkSchedule: {
            pos_id: {
                required,
                numeric,
            },
            user_id: {
                required,
                numeric,
            },
            mix_id: {
                required,
                numeric,
            },
            start_date: {
                required,
                minDate(value, model) {
                    if (this.isWorkSchedulesCreated && model.last_start_date && moment(value).isSame(model.last_start_date)) {
                        return true
                    }
                    return value >= moment().startOf('day').toDate()
                }
            },
            end_date: {
            },
            human_readable_frequencies: {
                required,
                minLength: minLength(1),
            },
            priority_order: {
                required,
            },
        },
    },
    computed: {
        isWorkSchedulesCreated() {
            return this.modalWorkSchedule.id > 0
        },
        i18nTitleModal() {
            if (this.isWorkSchedulesCreated) {
                return `${this.getI18n(i18nCommon, 'edit')} ${this.i18nWorkSchedule}`
            }
            else {
                return this.getI18n(i18nKey, 'BUTTONS.new_work_schedule')
            }
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nWorkSchedule() {
            return this.getI18n(i18nKey, 'TITLES.work_schedule')
        },
        modalOperation() {
            return this.isWorkSchedulesCreated ? 'edited' : 'created'
        },
        i18nInvalidDate() {
            return this.getI18n('ERROR_CODES', 'invalid_date')
        },
        i18nInvalidFrequency() {
            return this.getI18n('ERROR_CODES', 'invalid_frequency')
        },
        i18nFrequency() {
            return this.getI18n('COMMON', 'frequency')
        },
        i18nPriority() {
            return this.getI18n(i18nKey, 'FIELDS.priority')
        },
    },
    methods: {
        changePriority(option) {
            this.$v.modalWorkSchedule.priority_order.$model = option ? option.value : null
            this.$v.modalWorkSchedule.priority_order.$touch()
        },
        showModal(user, date) {
            this.isEdit = this.modalIsEdit
            this.submitLoading = false
            this.deleteLoading = false
            this.modalWorkSchedule = this.modal
            this.priority = this.priorityTypes.find(find => find.value === this.modalWorkSchedule.priority_order || find.value === 3) ?? null
            this.changePriority(this.priority)

            if (user) {
                this.modalWorkSchedule.user = user
                this.modalWorkSchedule.user_id = user.id
            }

            if (date) {
                this.modalWorkSchedule.start_date = moment(date, 'YYYY-MM-DD').toDate();
                this.modalWorkSchedule.end_date = moment(date, 'YYYY-MM-DD').toDate();
                this.modalWorkSchedule.human_readable_frequencies = ["ANY_DAY_OF_WEEK"];
            }

            this.$refs.formModal.show()
            this.resetValidation()
        },

        cleanModal() {
            this.modalWorkSchedule = Object.assign(this.modalWorkSchedule, {
                id: null,
                pos_id: null,
                pos: {
                    id: 0,
                    name: '',
                },
                user_id: null,
                user: {
                    id: 0,
                    name: '',
                },
                mix_id: null,
                mix: {
                    id: 0,
                    name: '',
                    products: [],
                },
                start_date: null,
                end_date: null,
                last_start_date: null,
                priority_order: '',
                human_readable_frequencies: [],
            })
            this.$v.modalWorkSchedule.$reset()
        },
        handleCancel() {
            if (this.isEdit) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },
        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },
        changePosId(e) {
            this.modalWorkSchedule.pos_id = e ? e.id : null
            this.$v.modalWorkSchedule.pos_id.$touch()
            this.clearResponseError('pos', 'modalWorkSchedule')
        },
        changeUserId(e) {
            this.modalWorkSchedule.user_id = e ? e.id : null
            this.$v.modalWorkSchedule.user_id.$touch()
            this.clearResponseError('user', 'modalWorkSchedule')
        },
        changeMixId(e) {
            this.modalWorkSchedule.mix_id = e ? e.id : null
            this.$v.modalWorkSchedule.mix_id.$touch()
            this.clearResponseError('mix', 'modalWorkSchedule')
        },
        handleOk() {
            this.$v.modalWorkSchedule.$touch()
            if (!this.$v.modalWorkSchedule.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },
        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            const workScheduleInfo = {
                frequencies: this.modalWorkSchedule.human_readable_frequencies,
                start: moment(this.modalWorkSchedule.start_date).format('YYYY-MM-DD'),
                end: moment(this.modalWorkSchedule.end_date).format('YYYY-MM-DD'),
                priority_order: this.modalWorkSchedule.priority_order,
            }

            if (!this.isWorkSchedulesCreated) {
                workScheduleInfo.user_id = this.modalWorkSchedule.user_id
                workScheduleInfo.point_of_sale_id = this.modalWorkSchedule.pos_id
                workScheduleInfo.mix_id = this.modalWorkSchedule.mix_id
            }
            const operation = this.isWorkSchedulesCreated
                ? WorkScheduleService.update(
                      this.modalWorkSchedule.id,
                      workScheduleInfo
                  )
                : WorkScheduleService.create(workScheduleInfo)

            const response = await operation
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.$refs.formModal.hide()
                this.resetValidation()
                this.positiveFeedback(
                    this.i18nWorkSchedule,
                    this.modalOperation,
                    '3'
                )
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
                if (
                    typeof response.data.errors.existing_workschedule !==
                    'undefined'
                ) {
                    this.negativeFeedback(
                        this.getI18n('RESPONSES', 'something_wrong'),
                        this.getI18n('ERROR_CODES', 'existing_workschedule')
                    )
                }
            }

            this.submitLoading = false
        },
        handleDelete() {
            if (this.deleteLoading) {
                return;
            }

            this.$refs.confirmationFormModal.showModal(false, this.deleteWorkSchedule)
        },
        async deleteWorkSchedule(deleteRecord) {
            if (!deleteRecord) {
                return;
            }

            const now = Date.now();
            const daysOfWeek = ['DAY_OF_WEEK_SUNDAY', 'DAY_OF_WEEK_MONDAY', 'DAY_OF_WEEK_TUESDAY', 'DAY_OF_WEEK_WEDNESDAY', 'DAY_OF_WEEK_THURSDAY', 'DAY_OF_WEEK_FRIDAY', 'DAY_OF_WEEK_SATURDAY'];
            const currentDayOfWeek = daysOfWeek[(new Date()).getDay()];

            if ((this.modalWorkSchedule.human_readable_frequencies.includes(currentDayOfWeek) || this.modalWorkSchedule.human_readable_frequencies.includes('ANY_DAY_OF_WEEK'))
                && now >= this.modalWorkSchedule.start_date.getTime() && now <= this.modalWorkSchedule.end_date.getTime()) {

                this.$refs.feedbackModal.showModal(401, 'A loja selecionada está em um roteiro corrente, não é possível apagar.')
                return;
            }

            this.$v.modalWorkSchedule.$touch()
            this.deleteLoading = true
            const response = await WorkScheduleService.delete(this.modalWorkSchedule.id)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nWorkSchedule, 'deleted', 3)
            } else if (statusCode === '417') {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            } else if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }

            this.deleteLoading = false
        },
    },
}
</script>
